import axios from './axios.js'

const url = '/admin/user'

export default {
  // 获取登录状态，未登录返回null，登录返回用户信息
  get: params => axios.get(url, { params }),

  // 开关
  switch: () => axios.get(url + '/switch'),
  turnOn: () => axios.get(url + '/switch-on'),
  turnOff: () => axios.get(url + '/switch-off')
}
