import axios from 'axios'
import qs from 'qs'

let baseURL = process.env.NODE_ENV === 'production' ? '//disc-api.onlytg.com/' : '//localhost:8888/'

const instance = axios.create({
  baseURL
})

instance.interceptors.request.use(config => {
  config.data = qs.stringify(config.data)
  return config
})


instance.interceptors.response.use(res => {
  if (res.status === 200) return res.data
  return res
})

export default instance