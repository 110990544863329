<template>
  <div id="app">
    <el-tabs class="h-100" tab-position="left" type="border-card">
      <el-tab-pane label="问卷管理">
        <user-tab />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import UserTab from './components/UserTab.vue'
export default {
  components: { UserTab }
}
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.h-100 {
  height: 100%;
}

.el-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.el-card__body {
  flex-grow: 1;
  overflow: auto;
}

.el-tab-pane,
.el-tabs__content {
  height: 100%;
}
</style>
