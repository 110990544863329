<template>
  <el-card>
    <div slot="header" class="d-flex justify-content-between align-items-start">
      <el-form inline :model="query">
        <el-form-item label="日期">
          <el-date-picker type="daterange" v-model="query.date" value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetch">查询</el-button>
        </el-form-item>
      </el-form>

      <div>
        <span>查看答案功能：</span>
        <el-button :type="switch1.type" @click="() => switch1.toggle()">{{ switch1.text }}</el-button>

        <el-button type="info" @click="handleExport">导出数据</el-button>
      </div>
    </div>
    <el-table id="ttt" :data="users">
      <el-table-column type="index" />
      <el-table-column prop="user.name" label="姓名" />
      <el-table-column prop="user.phone" label="手机" />
      <el-table-column prop="user.department" label="部门" />
      <el-table-column prop="wenjuan_id" label="问卷" :formatter="wenjuanFmt" />
      <el-table-column prop="score" label="得分" />
      <el-table-column prop="result" label="结果" />
      <el-table-column prop="created_at" label="测评时间" />
    </el-table>
  </el-card>
</template>
<script>
import UserApi from '../apis/user'
import User from '../models/user.js'
import Switch from '../models/switch.js'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { AdminRequest } from '../apis/request/admin'


export default {
  data () {
    return {
      // 开关
      switch1: {},
      query: {
        date: []
      },
      users: [],
      pickerOptions: {
        // shortcuts: [
        //   {
        //     text: '今天',
        //     onClick (picker) {
        //       picker.$emit('pick', [new Date(), new Date()]);
        //     }
        //   },
        //   {
        //     text: '本月',
        //     onClick (picker) {
        //       const end = new Date();
        //       const start = new Date(new Date().getFullYear(), 0);
        //       picker.$emit('pick', [start, end]);
        //     }
        //   },
        //   {
        //     text: '最近六个月',
        //     onClick (picker) {
        //       const end = new Date();
        //       const start = new Date();
        //       start.setMonth(start.getMonth() - 6);
        //       picker.$emit('pick', [start, end]);
        //     }
        //   }
        // ]
      },
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    wenjuanFmt (r, c, value) {
      return { 1: '情绪测评', 2: '心理稳定性测评', 3: '抗压能力' }[value]
    },
    async fetch () {
      this.switch1 = new Switch(await UserApi.switch())

      const [start, end] = this.query.date || []

      AdminRequest.post('/wenjuan/wenjuan/list', { start, end }).then(list => {

        // list.reduce((p, c) => { 
        //   const {id,wenjuan_id,score,result,created_at,updated_at} = c

        // }, [])


        this.users = list
      })
      // this.users = (await UserApi.get({ start, end })).map(i => new User(i))
    },
    handleDelete (row) {
      UserApi.delete(row.id).then(this.fetch)
    },
    handleExport () {
      if (this.users.length === 0) return

      let box = XLSX.utils.table_to_book(document.querySelector('#ttt'))

      let out = XLSX.write(box, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })

      try {
        FileSaver.saveAs(
          new Blob([out], {
            type: 'application/octet-stream'
          }),
          this.query.date + '评测结果.xlsx'
        )
      } catch (e) {
        // 错误处理方式
      }

    }
  }
}
</script>

