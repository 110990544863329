import UserApi from '../apis/user'

export default class Switch {
  constructor(data) {
    this.load(data)
  }

  load(data) {
    Object.assign(this, data)
  }

  get isOn() {
    return this.switch === 1
  }
  // 不同状态按钮的type不一样
  get type() {
    return this.isOn ? 'danger' : 'primary'
  }

  get text() {
    return this.isOn ? '关闭' : '开启'
  }

  turnOn() {
    return UserApi.turnOn()
  }

  turnOff() {
    return UserApi.turnOff()
  }

  async toggle() {
    let result = await (this.isOn ? this.turnOff() : this.turnOn())
    this.load(result)
  }
}
